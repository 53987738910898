<template>
  <div id="top " >
    <a ref="a" style="display:none"  href="#top"></a>
    
    <div>
      <img  class="_w-max _pc-mar-bottom-40 _mb-mar-bottom-20 wow fadeInUp _mb-display-none" :src="artcile.image_input" alt="">
      <img  class="_w-max _pc-mar-bottom-40 _mb-mar-bottom-20 wow fadeInUp _pc-display-none" :src="artcile_phone.image_input" alt="">
      <div v-if="!id" class="_text-center _mb-text-left  _pc-font-28 _b _pc-mar-bottom-25 _mb-mar-bottom-25 _mb-font-36">{{artcile.title}}</div>
      <div v-if="!id" class="_text-center _mb-text-left _mb-grey-99 _pc-font-14 _pc-mar-bottom-35 _mb-mar-bottom-35 _mb-font-22" style="line-height:2;" v-html="artcile.content"></div>
    </div>

    <div v-if="!id" class="_pc-display-none _mb-w-max">
      <div class="_white-bg _mb-mar-bottom-30 _mb-w-max" v-for="(item,index) in nav_list" :key="index"  style="border-radius:2vw;">
        <div class="_mb-pad-x-30 _mb-pad-y-30 _flex-row-bet-mid" @click.stop="giv(item.id)">
          <div class="_mb-font-36">{{item.title}}</div>
          <div class="_flex-row-mid _mb-font-24 theme-color" >
            <div>{{item.ishow?'收起':'展开'}}</div>
            <img class="_mb-w-20 _mb-mar-left-10 _transition-06" :style="item.ishow?'transform:rotate(0deg);':'transform:rotate(180deg);'" src="@/assets/image/phone-down-icon.png" alt="">
          </div>
        </div>
        <div class="_mb-pad-x-30" v-if="item.ishow">
          <div class="_mb-pad-y-30 _border-bottom _flex-row-bet-mid" v-for="(val,key) in item.artcile" :key="key" @click="get_id(val.id,item.id)">
            <div :class="id==val.id?'theme-color':''">{{val.title}}</div>
            <img class="_mb-w-20" src="@/assets/image/right-ico-04.png" alt="">
          </div>
        </div>
      </div>
    </div>


    <div v-if="id" class="pos-rel wow fadeInUp">

      <div class="_pc-mar-bottom-25 _mb-pad-bottom-30 _mb-flex-row-bet-mid _mb-border-bottom _mb-w-max">
        <div class="_flex-col-center-mid">
          <div class="_black _pc-font-28 _b _pc-mar-bottom-20 _mb-mar-bottom-15 _mb-font-36">{{data.title}}</div>

          <div class="_grey-99 _pc-font-14  _mb-font-22">发表日期：{{data.add_time}}</div>
        </div>
        <router-link to='/question/detail' class="_pc-display-none _mb-flex-row-mid theme-color _mb-font-24">
          <img class="_mb-w-25 _mb-mar-right-10" src="@/assets/image/fanhui.png" alt="">
          <div>返回</div>
        </router-link>
      </div>

      <div class="_grey-99 _pc-font-17 _mb-font-24 _mb-black _mb-pad-y-30" style="line-height:1.2;" v-html="data.content"></div>

      <div class="_pos-abs _pc-pad-x-10 _border-top _flex-row-bet-mid _pc-font-16 _mb-pad-top-30 _mb-font-24 _w-max _h-5-v _mb-display-block _mb-h-max" style="bottom: -0px;">
        <div class="_flex-row-mid _bottom-nav-040 _mb-w-max _mb-mar-bottom-20 " style="cursor:pointer;"><img class="_pc-w-10 _pc-mar-right-10 _mb-display-none" src="@/assets/image/left-ico-04.png" alt=""> <div class="_line-1" @click="to_last"> 上一篇：{{last_article?last_article.title:'没有更多了'}}</div> <img class="_mb-w-20 _mb-mar-left-10 _pc-display-none" src="@/assets/image/right-ico-04.png" alt=""></div>
        <div class="_flex-row-mid _text-right _mb-text-left _bottom-nav-040 _mb-w-max " style="cursor:pointer;"><div class="_line-1 " @click="to_next">下一篇：{{next_article?next_article.title:'没有更多了'}}</div> <img class="_pc-w-10 _pc-mar-left-10 _mb-w-20 _mb-mar-left-10" src="@/assets/image/right-ico-04.png" alt=""></div>
      </div>

      <div class="_w-max _h-max _pos-abs _flex-row-center-mid _b _grey-f8-bg _pc-font-38 _mb-font-38" style="z-index:11; opacity:0.7;top:0;left:0;" v-if="!is_show">~~~~文章正在加载~~~</div>
    </div>




    <div  class="_w-max wow fadeInUp" v-else>
      <div class="_w-max process-box ">
        <div class="_pc-pad-x-30 _pc-pad-y-35 _pos-rel" v-for="(item,index) in list" :key="index">
          <div class="_pc-font-22 _b _pc-mar-bottom-20 _mb-mar-bottom-5 _pos-rel _z-index-1">{{item.title}}</div>
          <div class="_line-3 _grey-99 _pc-font-14 _pc-mar-bottom-40 _mb-mar-bottom-20 _pos-rel _z-index-1" style="line-height:1.5;">{{item.synopsis}}</div>
          <div class="_pos-abs-bottom _flex-row-mid _pc-font-14 _z-index-1 _pc-mar-bottom-25 _mb-mar-bottom-5 _btn-hover " @click="get_id(item.id,item.f_id)"  style="cursor:pointer;color:var(--theme-color);">查看详情<img class="_pc-w-15 _pc-mar-left-10" src="@/assets/image/right-ico-02.png" alt=""></div>
          <img class="_pos-abs-top _w-max _h-max" style="left:0;" src="@/assets/image/que-bg.png" alt="">
        </div>
      </div>
    </div>

    
  </div>
</template>

<script>
export default {
  name: 'detail',
  props:{
    nav_list:{
      type:Array,
      default:[],
    },
  },
  data() {
    return {
      id:'',//文章id
      f_id:'',//父级id
      data:{},//当前文章内容
      childList:[],//当前类别文章列表

      next_article:{},//下一篇文章
      last_article:{},//上一篇

      artcile:{},//pc常见问题文章内容
      artcile_phone:{},//手机常见问题文章内容
      list:[],
      is_show:true,
    };
  },
  watch:{
    $route:{
      handler(newval,oldval){
        this.id = newval.query.id;
        this.f_id = newval.query.f_id;
        this.$route.query.checked = false;
        this.list = [];
      },
    },

    childList:{
      handler(){
        this.get_article();
         this.$nextTick(() => {
            this.$refs.a.focus()
        })
      },
    },

    id:{
      handler(newV){
        this.get_child_list();
        if(!this.id){
          this.get_news();
        }
      },
    }
  },

  mounted() {
    if(this.$route.query.id){
      this.id=this.$route.query.id;
      this.f_id=this.$route.query.f_id;
    }
    this.get_child_list();
    if(this.id){}else{this.get_news();}
  },
  methods: {
    get_article(){ //获取上一篇/下一篇
      let that = this;
      let child_index;
      that.childList.forEach((val,index)=>{
        if(val.id == that.id){
          that.data = val;
          child_index = index;
          that.last_article = that.childList[child_index+1];
          that.next_article = that.childList[child_index-1];
        }

      })
    },

    async get_child_list(){ //获取文章
      let that = this;
      that.is_show = false;
      await that.$axios.get('site/get_site_newList?page=1&limit=15&cid='+that.f_id).then(res=>{
        that.childList=res.data.data.all;
        that.is_show = true;
      })
    },

    to_next(){ //下一篇
      let that= this;
      if(that.next_article && that.next_article.id >=0){
        let id= that.next_article.id;
        let f_id = that.f_id;
        this.$router.push({path:'/question/detail',query:{id,f_id}})
      }else{return}
    },
    to_last(){ //上一篇
      let that= this;
      if(that.last_article && that.last_article.id >=0){
        let id= that.last_article.id;
        let f_id = that.f_id;
        this.$router.push({path:'/question/detail',query:{id,f_id}})
      }else{return}
    },


    async get_news(){  //获取常见问题内容
      let that = this;
      await that.$axios.get('get_article_tree?id=14',{}).then(res=>{
        that.artcile = res.data.data[0].artcile[0];
        that.artcile_phone = res.data.data[0].artcile[1] || {};
        res.data.data[0].child.forEach(val=>{
          val.artcile.forEach(value=>{
            value.f_id = val.id;
            if(that.list.length <= 5){
              if(value.is_banner ==1){
                that.list.push(value);
              }
            }
          })
        })
      })
    },


    get_id(id,f_id){
      this.$router.push({path:'/question/detail',query:{id,f_id}})
    },


    giv(id){
      this.$emit('giv',id);
    },
  },
};
</script>

<style scoped>
.process-box{
  display: grid;
  grid-template-columns: 17.70833vw 17.70833vw 17.70833vw;
  column-gap: 1.82292vw;
  row-gap: 1.5625vw;
}
._w-040{width:40%;}
._btn-hover:hover{opacity: 0.4;}
._h-5-v{height: 5vh;}
._transition-06{
  transition: 0.6s;
}
.bottom-nav-end{justify-content:flex-end;}
@media screen and (max-width: 768px) {
  ._bottom-nav-040{width:80%;}
  .bottom-nav-end{justify-content:flex-start;}
  .process-box{display: none;}
  ._h-5-v{height: 11.41553vh;}
}
</style>